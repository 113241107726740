import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import Htmlparser from "html-react-parser";
import { urlify } from "../strings";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    margin: "auto",
  },
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})((props) => <AccordionSummary {...props} />);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    direction: "rtl",
    textAlign: "justify",
  },
}))(AccordionDetails);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: "",
    Panels: this.props.Panels ? this.props.Panels : [],
    length: this.props.Panels.length ? this.props.Panels.length : 0,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidUpdate(prevProps) { 
    if (this.props.isActive !== prevProps.isActive && !this.props.isActive)
      this.setState({
        expanded: "",
      });
  }
  render() {
    const { expanded } = this.state;
    if (this.state.length > 0) {
      return (
        <div
          className={this.props.lang == "en" ? "infoSt-expa1" : "infoSt-expa"}
        >
          {this.state.Panels.map((object) =>
            object ? (
              <ExpansionPanel 
                square
                expanded={expanded === object.name}
                onChange={this.handleChange(object.name)}
              >
                <ExpansionPanelSummary>
                  <Typography>{Htmlparser(object.title)}</Typography>
                </ExpansionPanelSummary>
                {object.disable ? (
                  ""
                ) : (
                  <ExpansionPanelDetails>
                    <Typography>
                      {object.description
                        ? Htmlparser(
                            urlify(object.description, this.props.lang)
                          )
                        : ""}
                    </Typography>
                  </ExpansionPanelDetails>
                )}
              </ExpansionPanel>
            ) : (
              ""
            )
          )}
        </div>
      );
    } else return "";
  }
}

export default CustomizedExpansionPanel;
