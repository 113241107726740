import React, { Component } from "react";
import config from "./../config";

class RightArrow extends Component {
  render() {
    return (
      <div onClick={this.props.goToNextSlide} className="infoSt-arrow"
      tabindex="0" data-toggle="tooltip" title="السابق ">
        <img
          src={(config.cssJsHostPublic?config.cssJsHostPublic:"")+"/chatbotinfost/webchat_images/g.png"}
          className="infoSt-arro"
        />
      </div>
    );
  }
}

export default RightArrow;
