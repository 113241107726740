import React, { Component } from "react";
import "./Badge.css";
import config from "./config";

class Badge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatbotlang:
        !this.props.chatbotlang || typeof this.props.chatbotlang == "undefined"
          ? "ar"
          : this.props.chatbotlang,
    };
  }
  async componentDidMount() {
    await this.setState({ bottom: -64 });
    while (this.state.bottom < 15) {
      await new Promise((resolve) => setTimeout(resolve, 1));
      await this.setState((state) => ({ bottom: state.bottom + 1 }));
    }
  }

  componentDidUpdate() {
    //alert("chat User Languege=====", this.state.lang);
    //console.log("componentDidUpdate=========",window.chatbotlang ,this.state.chatbotlang )
    let oldlang = this.state.chatbotlang;
    let newlang =
      !window.chatbotlang || typeof window.chatbotlang == "undefined"
        ? "ar"
        : window.chatbotlang;
    //console.log("$$$$$$$=========oldlang=== ",oldlang,"$$$$$$$=========newlang===",newlang )
    if (oldlang != newlang) {
      this.setState({ chatbotlang: newlang });
      console.log(
        "%%%%%%==chatbot Language is updated and changed==%%%%%%",
        window.chatbotlang
      );
    }
  }

  render() {
    if (this.state.bottom === undefined) {
      return null;
    }
    return (
      <div
        className={
          this.state.chatbotlang == "ar" || config.company == "MOJ"
            ? "infoSt-badge"
            : "infoSt-badge-right"
        }
        style={{ bottom: `${this.state.bottom}px` }}
        onClick={this.props.onClick}
      >
        {config.company == "MOID" ? (
          <img
            alt="alfateen Avatar"
            src={
              (config.cssJsHostPublic ? config.cssJsHostPublic : "") +
              `/chatbotinfost/webchat_images/${
                this.state.chatbotlang == "ar"
                  ? "AlFateenAvatar__.gif"
                  : "Avatar-GIF-ENG.gif"
              }`
            }
            className="infoSt-fateen infoSt-envelope-top"
          />
        ) : window.innerWidth < 601 ? (
          <div className="infoSt-adel-mobile maad-bot">
            <p>{this.state.chatbotlang == "ar" ? "اسأل عادل" : "Ask Adel"}</p>
          </div>
        ) : (
          <img
            alt="Adel Avatar"
            src={
              (config.cssJsHostPublic ? config.cssJsHostPublic : "") +
              `/chatbotinfost/webchat_images/${
                this.state.chatbotlang == "ar" ? "ad.gif" : "ad-EN-2.gif"
              }`
            }
            className="infoSt-envelope infoSt-envelope-top"
          />
        )}
      </div>
    );
  }
}

export default Badge;
