import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import 'promise-polyfill/src/polyfill'; 
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./config";

const container = document.createElement("chat-app");
document.body.appendChild(container);

// loadGoogleAnalytics();
//ReactDOM.render(<App />, container); 
//console.log("=========chatbot============",window.chatbotlang )
//console.log("=========chatbotsystem_user_token============"+localStorage.getItem("chatbotsystem_user_token"))
  
if (config.cssJsHostPublic && config.cssJsHostPublic.length>1) console.log("=========Chatbot Remote Host============",config.cssJsHostPublic)
ReactDOM.render(<App   token={ localStorage.getItem("chatbotsystem_user_token")} chatbotlang={window.chatbotlang}  />, container);
//console.log("=========chatbotsystem_user_token after runder============"+localStorage.getItem("chatbotsystem_user_token"))
//ReactDOM.render(<App userid={window.chatbotsystem_user_id } lang={window.lang}  />, container);


// function loadGoogleAnalytics() {
//     const script = document.createElement("script");
//     script.src = "https://www.googletagmanager.com/gtag/js?id=G-D3E65F3S41";
//     script.async = true;
//     script.onload = () => {
//       window.dataLayer = window.dataLayer || [];
//       function gtag() {
//         window.dataLayer.push(arguments);
//       }
//       gtag("js", new Date());
//       gtag("config", "G-D3E65F3S41");
//     };
//     document.head.appendChild(script);
//   }
  