const pad = (string, length) => {
  const newString = "0000000000" + string;
  return newString.substr(newString.length - length);
};

export const getFullTime = (time) => {
  const timestamp = new Date(time);
  const currentTimestamp = new Date();

  const timestring = `${pad(timestamp.getHours(), 2)}:${pad(
    timestamp.getMinutes(),
    2
  )}`;

  if (currentTimestamp.toDateString() === timestamp.toDateString()) {
    return timestring;
  }
  timestamp.setHours(0);
  timestamp.setMinutes(0);
  timestamp.setSeconds(0);

  currentTimestamp.setHours(0);
  currentTimestamp.setMinutes(0);
  currentTimestamp.setSeconds(0);

  const daysDiff = Math.round(
    (currentTimestamp.getTime() - timestamp.getTime()) / 86400000
  );

  if (daysDiff === 1) {
    return `Yesturday ${timestring}`;
  }

  return `${pad(timestamp.getDate(), 2)}/${pad(timestamp.getMonth(), 2)}/${pad(
    timestamp.getFullYear(),
    4
  )} ${timestring}`;
};

export const urlify = (text,lang) => {
  let link = lang === "ar" ? "اضغط هنا" : "Click Here";
  var urlRegex = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  let final_html_with_new_lines=/* text_to_html( */text.toString().replace(urlRegex, function (url) {
    if (url.substring(0, 4) !== "http") url = "http:\\\\" + url;
    return `<a href="${url}" target="_blank">${link} </a>`;
  })//)
  return final_html_with_new_lines;
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};
 /* 
async function text_to_html (text)  {
  let final_html=""
  await text.split("\n").map(function (item) {
    
    final_html+= <span>{item}<br /></span>
  })
  console.log("final_html===",final_html)
  return final_html;
}; */
