import React, { Component } from "react";
import Badge from "./Badge";
import Chat from "./Chat";
import "./fonts/fontawesome/all.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMinimized: false,
      token: this.props.token ? this.props.token : 0,
      opened: false,
      lang: this.props.lang ? this.props.lang : "ar",
      chatbotlang:
        !this.props.chatbotlang || typeof this.props.chatbotlang == "undefined"
          ? "ar"
          : this.props.chatbotlang,
      value: "",
      username: "",
      password: "",
      error: "",
      has_user_token: false,
      interval: 0,
    };
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.resetInterval = this.resetInterval.bind(this);
    localStorage.removeItem("chatbotsystem_user_token");
    console.log("store chat_lang===========", this.state.chatbotlang);
    var lang_stored = window.localStorage.getItem("chatbotlang");
    if (!lang_stored || typeof lang_stored == "undefined") {
      console.log("%%%%%%window.localStorage.setItem  2");
      window.localStorage.setItem("chatbotlang", this.state.chatbotlang);
      lang_stored = this.state.chatbotlang;
    }

    setInterval(() => {
      let oldlang = lang_stored || this.state.chatbotlang; //value or "ar"
      let newlang =
        !window.chatbotlang || typeof window.chatbotlang == "undefined"
          ? "ar"
          : window.chatbotlang;
      if (oldlang == newlang) return;

      window.localStorage.setItem("chatbotlang", newlang);
      this.setState({ chatbotlang: newlang, interval: 1 });
      lang_stored = newlang;
    }, 2000);
  }
  dismissError() {
    this.setState({ error: "" });
  }

  resetInterval() {
    this.setState({
      interval: 0,
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    localStorage.setItem("chatbotsystem_user_token", new Date().getTime());
    this.setState({ has_user_token: true });
  }
  handleSignOut(evt) {
    evt.preventDefault();
    localStorage.removeItem("chatbotsystem_user_token");
    this.setState({
      username: "",
      password: "",
      error: "",
      has_user_token: false,
    });
  }

  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  onOpen = () => {
    this.setState({ opened: true });
  };
  onClose = () => {
    this.setState({ isMinimized: false, opened: false });
  };
  onMinimize = () => {
    this.setState({ isMinimized: true, opened: false });
  };

  render() {
    return (
      <div>
        {!this.state.opened ? (
          <Badge
            key="1"
            chatbotlang={this.state.chatbotlang}
            onClick={() => {
              loadGoogleAnalytics();
              this.onOpen();
            }}
          />
        ) : (
          <Chat
            onClose={this.onClose}
            onMinimize={this.onMinimize}
            resetInterval={this.resetInterval}
            chatbotlang={this.state.chatbotlang}
            interval={this.state.interval}
            token={this.state.token}
            isMinimized={this.state.isMinimized}
          />
        )}
      </div>
    );
  }
}
export default App;

function loadGoogleAnalytics() {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-4R7CX3B0PP";
  script.async = true;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-4R7CX3B0PP");
  };
  document.head.appendChild(script);
}
