require('dotenv').config()
export default {
  network: {
    //socketHostPublic: "https://chatbackend.infostrategic.com",
    //socketHostPublic: "http://192.168.10.109:3003",
       socketHostPublic: process.env.REACT_APP_SOCKET,  
    // socketHostPublic: "https://chatbotappstg.moid.gov.ae:3000",
    //socketHostPublic: "http://localhost:3003",
  },
 // cssJsHostPublic: "http://localhost:3005",//widget public url to get images, css and js files ... leave it "" if files exist on same host server for widget
   cssJsHostPublic: (process.env.REACT_APP_HTTPS=="true"?"https://":"http://")+
                    (process.env.REACT_APP_HOST+":"+process.env.REACT_APP_PORT),//widget public url to get images, css and js files ... leave it "" if files exist on same host server for widget
   company: process.env.REACT_APP_COMPANY,
   maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE, 
};
 