import React, { Component } from "react";
import { Box } from "@material-ui/core";

export default class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chat_lang: this.props.lang ? this.props.lang : "ar",
    };
    this.onClickUp = props.onClickUp;
    this.onClickDown = props.onClickDown;
  }
  render() {
    return (
      <div className="infoSt-evaluationContainer">
        <Box className="infoSt-iconFlex">
          <Box
            className="infoSt-iconStyle infoSt-like"
            onClick={this.onClickUp}
          >
            <i class="fal fa-thumbs-up"></i>
          </Box>

          <Box
            className="infoSt-iconStyle infoSt-dislike"
            onClick={this.onClickDown}
          >
            <i class="fal fa-thumbs-down"></i>
          </Box>
        </Box>
        <Box className="infoSt-evaluationText">
          <p className="infoSt-p">
            {this.state.chat_lang == "ar"
              ? "هل وجدت الجواب مفيداُ؟"
              : "Is this helpful?"}
          </p>
        </Box>
      </div>
    );
  }
}
